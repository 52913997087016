import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { selectLineColorById } from "../../reducers/devices.js";

function ColorPicker({ top, left, deviceId, name, setLineColor, setShowColorPicker }) {
    const [ colorValue, setColorValue ] = useState('');

    const oldLineColor = useSelector(selectLineColorById(deviceId));

    useEffect(() => {
        setColorValue(oldLineColor)
    }, [])

    const styles = {
        top: `${top}px`,
        left: `${left}px`
    }

    const handleSet = () => {
        setShowColorPicker(false);
        setLineColor(colorValue)
    }

    const handleCancel = () => {
        setShowColorPicker(false);
    }

    const handleColorChange = (e) => {
        setColorValue(e.target.value)
        
    }

    return (
        <div className="color-picker" style={styles}>   
            <div className="device-name-container">{name}</div>
            <div className="inputs-container" >
                <input type='color' onChange={handleColorChange} value={colorValue} style={{ borderColor: colorValue }} />
                <span style={{ color: colorValue }}>{colorValue}</span>
            </div>
            <div className="buttons-container">
                <button onClick={handleSet}>Set</button>
                <button onClick={handleCancel}>Cancel</button>
            </div>
        </div>
    );
}

export default ColorPicker;
