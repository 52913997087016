import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer } from "recharts";

import CustomTooltip from "./CustomTooltip.js";

import { getTicksForGraph } from "../../utils/utils.js";

function GraphSPLMulty({ data, dataKey }) {

    const { ticks, minValue, maxValue } = getTicksForGraph('Z', data, 0, 100);

    return (
        <div className="compare-graph-multiple">
            <ResponsiveContainer minHeight={600}>
                <LineChart margin={{ bottom: 30, top: 30 }} >
                    {
                        data.map(d => 
                            {   
                                return <Line 
                                            data={d.items} 
                                            name={d.name}
                                            type="monotone" 
                                            dataKey={dataKey}
                                            stroke={d.lineColor}
                                            strokeWidth={2} 
                                            key={d.name}
                                            activeDot={false} 
                                        />   
                            } 
                        )
                    }
                    <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                    <XAxis 
                        ticks={logAxis} 
                        interval={0} 
                        type="number" 
                        domain={[10,30000]} 
                        dataKey="freq" 
                        scale="log" 
                        tickSize='12' 
                        angle='45' 
                        label={{ position: 'insideTop', value: 'Frequency, Hz', fill: 'black', offset: 40 }}/>
                    <YAxis 
                        ticks={ticks} 
                        domain={[minValue, maxValue]} 
                        label={{ angle: -90, position: 'insideLeft', value: 'SPL, dB', fill: 'black', offset: 10 }}/>
                    <Tooltip content={<CustomTooltip XAxisDataKey='freq' YAxisDataKey={dataKey} />}/>
                </LineChart>
            </ResponsiveContainer>
        </div>
    );
}

export default GraphSPLMulty;

const logAxis = [
    10, 20, 30, 40, 50, 60, 70, 80, 90, 100,
    100, 200, 300, 400, 500, 600, 700, 800, 900, 1000,
    1000, 2000, 3000, 4000, 5000, 6000, 7000, 8000, 9000, 10000, 20000
]

