import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTrash, faVolumeHigh, faPlus, faGear } from '@fortawesome/free-solid-svg-icons';

import { 
    setFilter, 
    selectSizeCategoriesObject
} from '../../reducers/devices.js';
import { selectUserRole } from "../../reducers/auth.js";
import { toLowerCaseAndReplaceSymbolsWithDash } from "../../utils/utils.js";

import { selectMeasurements } from "../../reducers/measurements.js";

function DeviceListItem({ device, showItems, setClicked, setClickedDevice, setClickedDeviceVolume, setPoints }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const userRole = useSelector(selectUserRole);
    const categories = useSelector(selectSizeCategoriesObject);
    const measurements = useSelector(selectMeasurements);

    const handleTitleClick = (id, volume) => (e) => {

        let newFilter = {
            company: device.company,
            category: categories[device.category],
            deviceId: id
        };

		dispatch(setFilter({
			...newFilter
		}));

        const companyName = toLowerCaseAndReplaceSymbolsWithDash(device.company);
        const speakerName = toLowerCaseAndReplaceSymbolsWithDash(device.name);
        const speakerVolume = `volume-${volume}`

        navigate(`/calculations/${companyName}/${speakerName}/${speakerVolume}`);
    }

    const handleEditButton = (id, volume) => (e) => {
        const measure = measurements.find(m => m.id === id);

        navigate('/edit-device', { state: { 
            device: {
                name: device.name,
                company: device.company,
                category: device.category,
                size: device.size,
                volume: volume,
                id: id,
                measurements: measure,
                header: 'Edit Device'
            }
        }})
    }

    const handleAddNewVolume = (e) => {

        navigate('/edit-device', { state: { 
            device: {
                name: device.name,
                company: device.company,
                category: device.category,
                size: device.size,
                header: 'Add New Volume'
            }
        }})
    }

    const handleEditDeviceSettings = (e) => {

        navigate('/edit-device', { state: { 
            device: {
                name: device.name,
                company: device.company,
                category: device.category,
                size: device.size,
                volume: '',
                header: 'Edit General Settings'
            }
        }})
    }

    const handleDeleteButton = (id, volume) => (e) => {
        e.stopPropagation();
        e.preventDefault();

        setClicked(true);
        setClickedDevice(id);
        setClickedDeviceVolume(volume);
        setPoints({
            x: e.pageX,
            y: e.pageY
        });
    }

    if (!showItems) return;

    return (
        <div >
            {
                device.ids.map(({ id, volume }) => 
                    <div key={id} className="volume-list-item">
                        <div 
                            className="device-name" 
                            onClick={handleTitleClick(id, volume)}
                        >
                            <span className="element-underline-width">{device.name}</span>
                        </div>
                        <div>
                            <FontAwesomeIcon icon={faVolumeHigh} size='sm'/>  {volume} 
                        </div>
                        { userRole === 'admin' &&
                            <div 
                                className="volume-button edit" 
                                onClick={handleEditButton(id, volume)}
                            >
                                <FontAwesomeIcon icon={faPen} size='sm'/> Edit
                            </div>
                        }
                        { userRole === 'admin' &&
                            <div 
                                className="volume-button delete"
                                onClick={handleDeleteButton(id, volume)}
                            >
                                <FontAwesomeIcon icon={faTrash} size='sm'/> Delete
                            </div>
                        }
                    </div>
                )
            }
            { userRole === 'admin' &&
                <div className='add-new-volume-for-device' >
                    <div className='add-icon' onClick={handleAddNewVolume}>
                        <span>
                            <FontAwesomeIcon icon={faPlus}  size="lg" /> Add New Volume
                        </span>
                    </div>
                    <div className='edit-icon' onClick={handleEditDeviceSettings}>
                        <span>
                            <FontAwesomeIcon icon={faGear}  size="lg" /> Edit General
                        </span>
                    </div>
                </div>
            }
        </div>
    )
}

export default DeviceListItem;
