import { createSlice, current } from '@reduxjs/toolkit';

const initialState = {
    filteredDevices: [],
    currentVolumeTab: {
        name: null,
        items: []
    },
    currentGraph: 'bass',
    currentCompany: 'All',
    currentCategory: 'All',
    graph: {
        bass: { data: [] },
        spl: { data: [], weight: 'Z' },
        thd: { data: [] },
        slope: { data: [] },
    },
    categoriesObject: {
        'All': 'All',
        'xs': 'Mini',
        's': 'Small',
        'm': 'Medium',
        'l': 'Large',
        'xl': 'Extra Large'
    },
}

const compareSlice = createSlice({
    name: 'compare',
    initialState,
    reducers: {
        setFilteredDevices(state, action) {
            const { devices, company, category } = action.payload;

            state.currentCompany = company;
            state.currentCategory = category;

            state.filteredDevices = devices
                .filter(device => {
                    if (company === 'All') {
                        return true;
                    } else {
                        return device.company === company;
                    }
                })
                .filter(device => {
                    if (category === 'All') {
                        return true;
                    } else {
                        return initialState.categoriesObject[device.category] === category;
                    }
                })
        },
        setActiveGraph(state, action) {
            const { graph } = action.payload;

            state.currentGraph = graph;
        },
        handleActiveDevice(state, action) {
            const { id, name, lineColor, measurements } = action.payload;

            const idIndex = current(state.graph.bass.data).findIndex(item => item.id === id);

            // add device to the data array
            if (idIndex === -1) {

                const { bass, weights } = measurements.find(m => m.id === id);
                    
                const bassData = [
                    { 
                        Hz: bass['-10dB'].freq, 
                        SPL: bass['-10dB'].SPL.toFixed(2)
                    },
                    { 
                        Hz: bass['-3dB'].freq, 
                        SPL: bass['-3dB'].SPL.toFixed(2) 
                    },
                    {
                         Hz: bass['0dB'].freq, 
                         SPL: bass['0dB'].SPL.toFixed(2) 
                    },
                ]; 
                const thdData = weights.filter(w => w.THD > 0);
                const slopeData = {
                    '0dB': {
                        frequency: bass['0dB'].freq,
                        diff: '-'
                    },
                    '-3dB': {
                        frequency: bass['-3dB'].freq,
                        diff: Math.log2(bass['0dB'].freq / bass['-3dB'].freq).toFixed(2)
                    },
                    '-10dB': {
                        frequency: bass['-10dB'].freq,
                        diff: Math.log2(bass['0dB'].freq / bass['-10dB'].freq).toFixed(2)
                    },
                }

                state.graph.bass.data.push({ items: bassData, name, id, lineColor });
                state.graph.spl.data.push({ items: weights, name, id, lineColor });
                state.graph.thd.data.push({ items: thdData, name, id, lineColor });
                state.graph.slope.data.push({ items: slopeData, name, id, lineColor });
            } 
            // delete device from data array
            else {
                state.graph.bass.data.splice(idIndex, 1);
                state.graph.spl.data.splice(idIndex, 1);
                state.graph.thd.data.splice(idIndex, 1);
                state.graph.slope.data.splice(idIndex, 1);
            } 
        },
        deleteGraphDevice(state, action) {
            const { id } = action.payload;

            const idIndex = current(state.graph.bass.data).findIndex(item => item.id === id);

            state.graph.bass.data.splice(idIndex, 1);
            state.graph.spl.data.splice(idIndex, 1);
            state.graph.thd.data.splice(idIndex, 1);
            state.graph.slope.data.splice(idIndex, 1);
        },
        handleVolumeTab(state, action) {
            const { ids, name } = action.payload;

            const isExistAndSame = state.currentVolumeTab.name !== null && state.currentVolumeTab.name === name;

            if (isExistAndSame) {
                state.currentVolumeTab = { name: null, items: [] };
            } else {
                state.currentVolumeTab = { name, items: [...ids] };
            }
        },
        setSPLWeight(state, action) {
            const { weight } = action.payload;

            state.graph.spl.weight = weight;
        },
        setGraphsLineColor(state, action) {
            const { color, id } = action.payload;

            const idIndex = current(state.graph.bass.data).findIndex(item => item.id === id);

            state.graph.bass.data[idIndex].lineColor = color;
            state.graph.spl.data[idIndex].lineColor = color;
            state.graph.thd.data[idIndex].lineColor = color;
            state.graph.slope.data[idIndex].lineColor = color;
        },
        setTabItemsLineColor(state, action) {
            const { color, id } = action.payload;

            const idIndex = current(state.currentVolumeTab.items).findIndex(item => item.id === id);

            state.currentVolumeTab.items[idIndex].lineColor = color;

        }
    },
})

export const selectFilteredDevices = state => state.compare.filteredDevices;

export const selectCurrentVolumeTab = state => state.compare.currentVolumeTab;

export const selectCurrentGraph = state => state.compare.currentGraph;
export const selectCurrentCompany = state => state.compare.currentCompany;
export const selectCurrentCategory = state => state.compare.currentCategory;
export const selectBassGraphData = state => state.compare.graph.bass.data;
export const selectSPLGraphData = state => state.compare.graph.spl.data;
export const selectTHDGraphData = state => state.compare.graph.thd.data;
export const selectSlopeGraphData = state => state.compare.graph.slope.data;
export const selectSPLWeight = state => state.compare.graph.spl.weight

export const { 
    setFilteredDevices, 
    handleActiveDevice,
    deleteGraphDevice,
    handleVolumeTab, 
    setSPLWeight, 
    setActiveGraph, 
    setGraphsLineColor,
    setTabItemsLineColor
} = compareSlice.actions;

export default compareSlice.reducer;

