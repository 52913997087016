
function TableSlopeMulty({ data }) {
    const tableHeader = ['-10dB', '-3dB', '0dB'];

    if (data.length < 1) return;
    
    return (
        <div>
            <div className="slope-table-container">
                
                <div className='slope-table-header' style={{ position: 'sticky'}}  >
                    { tableHeader.map(h =>
                        <div key={h}>
                            {h}
                        </div>							
                    )}
                </div>

                { data.map(d =>
                    <div className='slope-table-row-container' key={d.id} >
                        <div className='slope-table-row'>
                            <div className='slope-table-row-name' >
                                <div className="device-name-field" style={{ color: d.lineColor }} >{d.name}</div>
                                <div className="frequency-field" >Frequency, [Hz]</div>
                                <div className="rate-field" >Rate, [Octave]</div>
                            </div>
                            <div className='slope-table-row-data' >
                                <div>{d.items['-10dB'].frequency}</div>
                                <div>{d.items['-3dB'].frequency}</div>
                                <div>{d.items['0dB'].frequency}</div>
                                
                                <div>{d.items['-10dB'].diff}</div>
                                <div>{d.items['-3dB'].diff}</div>
                                <div>{d.items['0dB'].diff}</div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default TableSlopeMulty;


