import { useState, useRef, createRef, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import PanelFormInput from "../PanelForm/PanelFormInput.js";
import PanelFormUpload from "../PanelForm/PanelFormUpload.js";
import ModalNewDevice from '../Modal/ModalNewDevice.js';
import Loader from '../Loader/Loader.js';

import { 
    updateDevice, 
    selectDevicesStatus, 
    updateDeviceGeneralSettings, 
    registerDevice
} from '../../reducers/devices.js';
import { 
    updateMeasurement, 
    updateMeasurementsByName 
} from '../../reducers/measurements.js';

import { chooseCategory } from '../../utils/utils.js';
import VolumeTabItem from '../PanelForm/VolumeTabItem.js';

function EditSingleDeviceForm() {
    const [modal, setModal] = useState(false);

    const [ volumeTab, setVolumeTab ] = useState(
        { 
            id: 0, 
            refProp: createRef(), 
            file: {
                name: '',
                isValid: false,
                measurements: []
            }
        }
    )

    const status = useSelector(selectDevicesStatus);

    const navigate = useNavigate();
    const location = useLocation();
    const { device } = location.state;
    
    const dispatch = useDispatch();

    useEffect(() => {
        if (device.header === 'Edit Device') {
            setVolumeTab({ 
                ...volumeTab, 
                file: { 
                    name: `${device.name}_vol${device.volume}.csv`, 
                    isValid: true, 
                    measurements: device.measurements.items 
                } 
            })
        } 
        else if (device.header === 'Edit General Settings') {
            setVolumeTab({ 
                ...volumeTab, 
                file: { 
                    name: '', 
                    isValid: true, 
                    measurements: []
                } 
            })
        }
    }, [ device ])

    const inputName = useRef(null);
    const inputCompany = useRef(null);
    const inputSize = useRef(null);

    const handleSubmit = async (e) => {
        e.preventDefault();

        const size = inputSize.current.value.trim().replace(',', '.');
        const category = chooseCategory(+size);

        if (device.header === 'Edit General Settings') {
            const newGeneralSettings = {
                oldName: device.name,
                name: inputName.current.value.trim(),
                company: inputCompany.current.value.trim(),
                size,
                category,
            }

            await dispatch(updateDeviceGeneralSettings(newGeneralSettings))
        } 
        else if (device.header === 'Edit Device')
        {
            const { measurements } = volumeTab.file;

            const newDevice = {
                id: device.id,
                name: inputName.current.value.trim(),
                company: inputCompany.current.value.trim(),
                volume: volumeTab.refProp.current.value.trim(),
                size,
                category,
                measurements
            };

            await dispatch(updateDevice(newDevice));
            await dispatch(updateMeasurement(newDevice.id));
        }
        else if (device.header === 'Add New Volume') {
            const { measurements } = volumeTab.file;

            const newDevice = {
                name: inputName.current.value.trim(),
                company: inputCompany.current.value.trim(),
                volume: volumeTab.refProp.current.value.trim(),
                size,
                category,
                measurements
            };

            const copyOfDevice = structuredClone([newDevice]);

            await dispatch(registerDevice(newDevice));
            await dispatch(updateMeasurementsByName(copyOfDevice));
        }

        setModal(true);
    }

    const handleCancel = (e) => {
        e.preventDefault();
        
        navigate('/devices');
    }

    const handleModalClose = (e) => {
        e.preventDefault();

        setModal(false);

        navigate('/devices');
    }

    const addNewFile = (id, file) => {
        setVolumeTab({...volumeTab, file});
    }

    if (status === 'loading')
        return <Loader />

    const inputDisabled = device.header !== 'Edit General Settings';

  	return (
        <div>
            { modal && <ModalNewDevice handleModalClose={handleModalClose} />}
            <div className='panel-form'>
                <h1>{device.header}</h1>
                <form id='add-feedback-form' onSubmit={handleSubmit}>
                    <PanelFormInput ref={inputName} text="Device's name" defaultValue={device.name} disabled={inputDisabled} />
                    <PanelFormInput ref={inputCompany} text="Company's name" defaultValue={device.company} disabled={inputDisabled} />
                    <PanelFormInput ref={inputSize} text="Device's Size in litres" defaultValue={device.size} disabled={inputDisabled} />
                    <div className='volume-tabs-wrapper'>
                        <div className='volume-tabs-container'>
                            { inputDisabled &&
                                <VolumeTabItem 
                                    tab={volumeTab}
                                    defaultValue={device.volume}
                                    addNewFile={addNewFile}
                                    handleDeleteVolumeTab={() => {}}
                                />
                            }
                        </div>
                    </div>
                    <PanelFormUpload 
                        isFilesValid={volumeTab.file.isValid}
                        handleCancel={handleCancel} />
                </form>
            </div>
        </div>

  	);
}

export default EditSingleDeviceForm;
