import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import ContextMenu from "./ContexMenu.js";
import ColorPicker from "./ColorPicker.js";

import { setLineColor } from "../../reducers/devices.js";
import { selectMeasurements } from "../../reducers/measurements.js";
import { 
    handleActiveDevice,
    selectBassGraphData,
    selectCurrentVolumeTab,
    selectFilteredDevices,
    setGraphsLineColor,
    setTabItemsLineColor,
} from "../../reducers/compare.js";

import { makeCsvFromMeasurements } from "../../utils/utils.js";

function VolumeTab() {
    const [ clicked, setClicked ] = useState(false);
    const [ clickedDeviceId, setClickedDevice ] = useState(null);
    const [ clickedDeviceName, setClickedDeviceName ] = useState('');
    const [ points, setPoints ] = useState({ x: 0, y: 0 });
    const [ showColorPicker, setShowColorPicker ] = useState(false);

    const dispatch = useDispatch();
    
    const tab = useSelector(selectCurrentVolumeTab);
    const filteredDevices = useSelector(selectFilteredDevices);
    const graphDevices = useSelector(selectBassGraphData);

    const measurements = useSelector(selectMeasurements);
    
    const handleAddDevice = (id, lineColor, volume) => (e) => {
        dispatch(handleActiveDevice({ id, lineColor, measurements, name: `${tab.name}_vol${volume}` }));
    }

    const handleRightClick = (id, name) => (e) => {
        e.preventDefault();

        setClicked(true);
        setClickedDevice(id);
        setClickedDeviceName(name);
        setPoints({
            x: e.pageX,
            y: e.pageY
        });
    }

    const handleDownload = () => {
        const deviceMeasurement = measurements.find(m => m.id === clickedDeviceId).items;
        
        const csv = makeCsvFromMeasurements(deviceMeasurement);

        const link = document.createElement('a');
        link.download = `${clickedDeviceName}.csv`;
        link.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
        link.click();
    }

    const handleLineColor = (color) => {
        const isSelected = graphDevices.findIndex(item => item.id === clickedDeviceId);
        
        dispatch(setLineColor({ color, id: clickedDeviceId }));
        dispatch(setTabItemsLineColor({ color, id: clickedDeviceId }));
        if (isSelected > -1) {
            dispatch(setGraphsLineColor({ color, id: clickedDeviceId }))
        }    
    }

    const isExist = filteredDevices.findIndex(device => device.name === tab.name) !== -1;

    if (tab.name === null || !isExist) return;

    return (
        <div className="filter-box">
            { 
                tab.items.map(({ id, volume, lineColor }) => {
                    const isSelected = graphDevices.findIndex(item => item.id === id);

                    return <div
                        key={id}
                        className={`filter-box-item ${isSelected > -1 ? 'active' : ''}`}
                        style={{ color: isSelected > -1 ? lineColor : '' }}
                        onClick={handleAddDevice(id, lineColor, volume)}
                        onContextMenu={handleRightClick(id, `${tab.name}_vol${volume}`)}
                    >
                        {tab.name}_vol{volume}
                    </div>
                })
            }
            { clicked && 
                <ContextMenu 
                    top={points.y} 
                    left={points.x}
                    handleDownload={handleDownload} 
                    setShowColorPicker={setShowColorPicker}
                    setClicked={setClicked}
                /> 
            }
            { showColorPicker && 
                <ColorPicker 
                    top={points.y} 
                    left={points.x}
                    deviceId={clickedDeviceId} 
                    name={clickedDeviceName} 
                    setLineColor={handleLineColor}
                    setShowColorPicker={setShowColorPicker}
                /> 
            }
        </div>
    );
}

export default VolumeTab;
