import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectAuthStatus, selectAuthError } from "../../reducers/auth.js";

import Loader from '../Loader/Loader.js';

import { 
    signinUser
} from "../../reducers/auth.js";

import oauth from '../../assets/oauth_yandex.webp';

const REACT_APP_OAUTH_APP_ID = process.env.REACT_APP_OAUTH_APP_ID;

const OAUTH_REQUEST_BASE_URL = 'https://oauth.yandex-team.ru';
const OAUTH_REQUEST_URL = `${OAUTH_REQUEST_BASE_URL}/authorize?response_type=token&client_id=${REACT_APP_OAUTH_APP_ID}`;

function SignForm() {
    const dispatch = useDispatch();

    const inputName = useRef(null);
    const inputPass = useRef(null);

    const status = useSelector(selectAuthStatus);
    const error = useSelector(selectAuthError);

    const handleSubmit = (e) => {
        e.preventDefault();
        
        const user = {
            name: inputName.current.value,
            password: inputPass.current.value
        }

        dispatch(signinUser(user));        
    }

    const handleOAuthLogin = () => {
        window.location.assign(OAUTH_REQUEST_URL);
    }

    if (status === 'loading')
        return <Loader />


    return (
        <div>
            <form id='sign-form' onSubmit={handleSubmit}>
                <input 
                    required 
                    ref={inputName}
                    type="name" 
                    id="name" 
                    placeholder="Username"
                />
                <input 
                    required 
                    ref={inputPass}
                    type="password" 
                    id="password" 
                    placeholder="Password" 
                />
                <button type="submit" className="btn-black">Login</button>
            </form>
            <img className='oauth-button' alt='oauth' src={oauth} 
                onClick={handleOAuthLogin}
            />
            { error &&
                <div className="status-text">⛔ Error: "{error}"</div>
            }
        </div>
    )
}

export default SignForm;
