import { useState } from "react";

import DeletePrompt from "./DeletePrompt.js";
import DeviceListItem from "./DeviceListItem.js";
import DeviceListItemsHeader from "./DeviceListItemsHeader.js";

function DeviceListItems(device) {
    const [ showItems, setshowItems ] = useState(false);

    const [ clicked, setClicked ] = useState(false);
    const [ clickedDeviceId, setClickedDevice ] = useState(null);
    const [ clickedDeviceVolume, setClickedDeviceVolume ] = useState('');
    const [ points, setPoints ] = useState({ x: 0, y: 0 });

    return (
        <div className="devices-list-item-container" >
            <DeviceListItemsHeader 
                name={device.name}
                company={device.company}
                size={device.size}
                category={device.category}
                showItems={showItems}
                setshowItems={setshowItems}
            />

            <DeviceListItem 
                device={device}
                showItems={showItems}
                setClicked={setClicked}
                setClickedDevice={setClickedDevice}
                setClickedDeviceVolume={setClickedDeviceVolume}
                setPoints={setPoints}
            />

            { clicked && 
                <DeletePrompt                
                    id={clickedDeviceId}
                    name={device.name}
                    volume={clickedDeviceVolume}
                    top={points.y} 
                    left={points.x}
                    setClicked={setClicked} 
                />
            }
        </div>
    )
}

export default DeviceListItems;
