import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faCircleCheck, faCircleXmark, faFileUpload } from '@fortawesome/free-solid-svg-icons';

import PanelFormVolumeInput from "./PanelFormVolumeInput.js";
import FileName from './FileName.js';

import { csvToJson, txtToJson } from '../../utils/utils.js';

function VolumeTabItem({ tab, addNewFile, handleDeleteVolumeTab, defaultValue }) {
    const { refProp, id, file: { name, isValid } } = tab;

    const handleFileUpload = (e) => {
        if (!e.target.files) return;
        const { files } = e.target;
        const { name } = files[0];

        const reader = new FileReader();

        reader.onload = (e) => {
            const { result } = e.target;
            
            if (result) {
                let isValid, fileData;
                const fileType = name.split('.').pop();

                if (fileType === 'csv' || fileType === 'txt') {
                    if (fileType === 'csv') {
                        ({ isValid, fileData } = validate(csvToJson(result)));
                    }
                    else if (fileType === 'txt') {
                        ({ isValid, fileData } = validate(txtToJson(result)));
                    } 

                    addNewFile(id, {
                        isValid,
                        name,
                        measurements: fileData,
                    });
                } else {
                    addNewFile(id, {
                        isValid: false,
                        name: 'Incorrect file type',
                        measurements: [],
                    });
                }
            }
        }

        reader.readAsText(files[0]);
    };

    const validate = (rawData) => {
        let isValid = true;
        const fileData = [];

        for (let i = 0; i < rawData.length; i++) {
            for (const [ key, value ] of Object.entries(rawData[i])) {
                if (isNaN(value)) {
                    return {
                        isValid: false,
                        fileData
                    } 
                } else if (key !== 'Freq[Hz]' && key !== 'CHB[dBSPL]' && key !== 'Phase[Deg]' && key !== 'THD[%]' && key !== 'R&B[%]') {
                    return {
                        isValid: false,
                        fileData
                    }
                }
            }

            fileData.push(rawData[i]);
        }
        
        return {
            isValid,
            fileData
        };
    }

    const iconStyleValidation = {
        color: isValid ? 'green' : 'red'
    }

    const iconValidation = isValid ? faCircleCheck : faCircleXmark;

  	return (
        <div key={id} className="volume-tab">
            <PanelFormVolumeInput defaultValue={defaultValue} ref={refProp} id={id} text="Volume in %" placeholder="Type Volume" />

            <div className='add-txt-item'>
                <label className='icons-label upload-txt-lable'>
                    <FontAwesomeIcon icon={faFileUpload}  size="2xl" />
                    <input id={`${id}-file`} className='upload-txt-file' type="file" onChange={handleFileUpload} />
                </label>
            </div>

            <div 
                className='delete-tab-item'
                onClick={handleDeleteVolumeTab(id)}>
                    <label className='icons-label delete-tab-lable'>
                        { id !== 0 && <FontAwesomeIcon icon={faTrashAlt}  size="2xl" />}
                    </label>
            </div>

            <div className='file-name'>
                { name !== '' && <FileName name={name}/> }
            </div>
            <div className='validation-message'>
                { name !== '' &&
                    <div className='validation-container'>
                        <FontAwesomeIcon style={iconStyleValidation} icon={iconValidation}  size="2xl" />
                    </div>
                }
            </div>
        </div>

  	);
}

export default VolumeTabItem;

