import { useState } from 'react';

import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, Legend, ReferenceLine } from "recharts";

function GraphSPL({ graphData, rawData, THDData, averageSPL }) {
    const [ dataKey, setDataKey ] = useState('Z');
    const [ refLine, setRefLine ] = useState({
        value: 10,
        text: '±6dB',
        show: true
    });

    const handleShowGraphData = (wieght) => (e) => {
        setDataKey(wieght);
    }

    const handleRefLine = (e) => {
        if(refLine.value === 10) {
            setRefLine({
                value: 6,
                text: '±3dB',
                show: true
            });
        }

        if(refLine.value === 6) {
            setRefLine({
                value: 3,
                text: 'No',
                show: true
            });
        }
        
        if(refLine.value === 3) {
            setRefLine({
                value: 0,
                text: '±10dB',
                show: false
            });
        }

        if(refLine.value === 0) {
            setRefLine({
                value: 10,
                text: '±6dB',
                show: true
            });
        }
    }

    return (
        <div>
            <div className="graph-type">
                <div className='choose-table-type'>
                    <div onClick={handleShowGraphData('Z')} className={`choose-table-type-item ${dataKey === 'Z' ? 'active' : ''}`}> Show Z-weightened</div>
                    <div onClick={handleShowGraphData('A')} className={`choose-table-type-item ${dataKey === 'A' ? 'active' : ''}`}> Show A-weightened</div>
                    <div onClick={handleShowGraphData('C')} className={`choose-table-type-item ${dataKey === 'C' ? 'active' : ''}`}> Show C-weightened</div>
                    <div onClick={handleShowGraphData('raw')} className={`choose-table-type-item ${dataKey === 'raw' ? 'active' : ''}`}> Show Raw</div>
                    <div onClick={handleRefLine} className="choose-table-type-item">Set {refLine.text} Reference</div>
                </div>
            </div>

            <LineChart 
                width={900} height={450} margin={{ bottom: 30 }} 
                style={{width: '950px', margin: '30px auto'}}>
                <Line 
                    yAxisId="left"
                    data={dataKey === 'raw' ? rawData : graphData} 
                    type="monotone" 
                    dataKey={dataKey} 
                    stroke="#8884d8"  
                    strokeWidth={2} 
                    dot={dataKey !== 'raw'}
                />
                <Line 
                    yAxisId="right"
                    data={THDData} 
                    type="monotone" 
                    dataKey="THD" 
                    stroke="#3498db"  
                    strokeWidth={2} 
                />
                { refLine.show &&
                    <ReferenceLine 
                        yAxisId="left"
                        y={averageSPL} 
                        label={{position: {x: 50, y: 5}, value: averageSPL.toFixed(2) , fill: 'black'}} 
                        stroke="green" />
                }
                { refLine.show &&
                    <ReferenceLine 
                        yAxisId="left"
                        y={averageSPL + refLine.value} 
                        label={{position: 'insideBottomLeft', value: (averageSPL + refLine.value).toFixed(2)}}
                        stroke="green" 
                        strokeDasharray="10 10" 
                        strokeWidth={2}/>
                }
                { refLine.show &&
                    <ReferenceLine 
                        yAxisId="left"
                        y={averageSPL - refLine.value}
                        label={{position: 'insideTopLeft', value: (averageSPL - refLine.value).toFixed(2)}}
                        stroke="green"
                        strokeDasharray="10 10"
                        strokeWidth={2}/>
                }
                <CartesianGrid stroke="#ccc" strokeDasharray="5 5"  />
                <XAxis 
                    ticks={logAxis} 
                    interval={0} 
                    type="number" 
                    domain={[10,20000]} 
                    dataKey="freq" 
                    scale="log" 
                    tickSize='12' 
                    angle='45' 
                    label={{ position: 'insideTop', value: 'Frequency, Hz', fill: 'black', offset: 40 }}/>
                <YAxis 
                    yAxisId="left"
                    domain={[0, 120]} 
                    label={{ angle: -90, position: 'insideLeft', value: 'SPL, dB', fill: 'black', offset: 10 }}
                    />
                <YAxis 
                    yAxisId="right"
                    orientation="right"
                    domain={[0, 80]} 
                    label={{ angle: 90, position: 'insideRight', value: 'THD, %', fill: 'black', offset: 10 }}
                />
                <Tooltip />
                <Legend verticalAlign="top" height={36} />
            </LineChart>
        </div>
    );
}

export default GraphSPL;

const logAxis = [
    10, 20, 30, 40, 50, 60, 70, 80, 90, 100,
    100, 200, 300, 400, 500, 600, 700, 800, 900, 1000,
    1000, 2000, 3000, 4000, 5000, 6000, 7000, 8000, 9000, 10000, 20000, 30000
]
