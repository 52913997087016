import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Loader from '../Loader/Loader.js';

import { signinYandex, selectIsSigned, selectAuthStatus, selectAuthError } from "../../reducers/auth.js";

function OAuthTokenPage() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const status = useSelector(selectAuthStatus);
    const error = useSelector(selectAuthError);
    const isSigned = useSelector(selectIsSigned);

    const queryParameters = new URLSearchParams(window.location.hash.replace('#', '?'));
    const token = queryParameters.get('access_token');

    useEffect(() => {
        if (isSigned) {
            navigate('/');
        } else if (token !== null) {
            dispatch(signinYandex(token))
        }
    }, [ token, isSigned ]);

    if (status === 'loading')
        return <Loader />

    return (
        <div>
            <div className="status-text">⛔ Error: "{error}"</div>
        </div>
    )
}

export default OAuthTokenPage;
