import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd, faCancel } from '@fortawesome/free-solid-svg-icons';

const iconStylePaddingRight = {
    paddingRight: '10px'
}

const PanelFormUpload = ({ isFilesValid, handleCancel }) => {
    return (
        <div className='upload-container'>
            <div className='upload-container-btns'>
                <button type="submit" className="btn btn-blue" disabled={!isFilesValid} >
                    <FontAwesomeIcon icon={faAdd} style={iconStylePaddingRight}/>
                    Submit
                </button>
                <button className="btn btn-cancel" onClick={handleCancel}>
                    <FontAwesomeIcon icon={faCancel} style={iconStylePaddingRight}/>
                    Cancel
                </button>
            </div>
        </div>
    )
}


export default PanelFormUpload;
