import { useEffect } from "react";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPalette, faDownload } from '@fortawesome/free-solid-svg-icons';

function ContextMenu({ top, left, setClicked, handleDownload, setShowColorPicker }) {
    
    useEffect(() => {
        const handleClick = (e) => setClicked(false);

        document.addEventListener("click", handleClick);

        return () => {
          document.removeEventListener("click", handleClick);
        };
      }, []);

    const styles = {
        top: `${top}px`,
        left: `${left}px`
    }

    const handleSetColor = (e) => {
        e.stopPropagation(); 
        setClicked(false);
        setShowColorPicker(true);
    }

    return (
        <div className="context-menu" style={styles}>
            <ul>
                <li onClick={handleDownload}>Download <FontAwesomeIcon icon={faDownload} size='sm'/></li>
                <li onClick={handleSetColor}>Set Color <FontAwesomeIcon icon={faPalette} size='sm'/></li>
            </ul>
        </div>
    );
}

export default ContextMenu;
