import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAudio } from '@fortawesome/free-solid-svg-icons';

function FileName({ name }) {

    return (
        <div className='file-name-inner-container'>
            <div><FontAwesomeIcon icon={faFileAudio}  size="2xl" /> </div>
            <div>{name}</div>
        </div>
    );
}

export default FileName;
