import { useEffect } from "react";
import { useDispatch } from 'react-redux';

import { deleteDevice } from '../../reducers/devices.js';
import { deleteMeasurement } from '../../reducers/measurements.js';

function DeletePrompt({ id, volume, name, top, left, setClicked }) {
    const dispatch = useDispatch();
    
    useEffect(() => {
        const handleClick = (e) => setClicked(false);

        document.addEventListener("click", handleClick);

        return () => {
          document.removeEventListener("click", handleClick);
        };
      }, []);

    const styles = {
        top: `${top}px`,
        left: `${+left - 200}px`
    }

    const handleDeleteDevice = (id) => async (e) => {
        await dispatch(deleteDevice(id));
        await dispatch(deleteMeasurement(id))
    }

    return (
        <div className="prompt" style={styles}>
            <span>Delete <b>{name}</b>  Volume {volume}? </span>
            <button 
                className="prompt-yes-btn" 
                onClick={handleDeleteDevice(id)}
            >
                Yes
            </button>
            <button className="prompt-cancel-btn">Cancel</button>
        </div>
    );
}

export default DeletePrompt;
