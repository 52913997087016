import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { selectIsSigned } from '../../reducers/auth.js';

import boombox from '../../assets/boom-box.png';

const Home = ({ handleSignClick }) => { 

    let navigate = useNavigate();

    const isSigned = useSelector(selectIsSigned);

    const handleButtonClick = (e) => {
        if (isSigned) {
            navigate('/devices')
        } else {
            handleSignClick(e)
        }
    }

    return (
        <section className='home'>
            <div className='welcome'>
                <div>
                    <h1><div className='features'>Analyze sound devices</div> Sound <br /> Performance Map</h1>
                    <p>App is intended to create and analyze different audio characteristics of devices and vizualize raw sound data.</p>
                    <button 
                        className='btn-black' 
                        onClick={handleButtonClick}>
                            See devices
                    </button>
                </div>
                <img alt='boombox' src={boombox}></img>
            </div>
        </section>
    );
}

export default Home;