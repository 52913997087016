import { createRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import VolumeTabItem from './VolumeTabItem.js';

import { createUID } from '../../utils/utils.js';

function VolumeTabs({ volumeTabs, setVolumeTabs }) {

    const hanbleAddVolumeTab = () => {
        const newId = createUID();

        setVolumeTabs([...volumeTabs, {
            id: newId,
            refProp: createRef(),
            file: {
                name: '',
                isValid: false,
                measurements: []
            }
        }]);
    }

    const handleDeleteVolumeTab = (id) => () => {
        const newVolumeTabs = volumeTabs.filter((tab) => tab.id !== id);
        
        setVolumeTabs([...newVolumeTabs]);
    }

    const addNewFile = (id, file) => {
        const newVolumeTabs = volumeTabs.map((tab) => tab.id === id ? {...tab, file} : tab);
    
        setVolumeTabs([...newVolumeTabs]);
    }

  	return (
        <div className='volume-tabs-wrapper'>
            <div className='volume-tabs-container'>
                { volumeTabs.map(tab => 
                    <VolumeTabItem 
                        tab={tab}
                        key={tab.id} 
                        handleDeleteVolumeTab={handleDeleteVolumeTab}
                        addNewFile={addNewFile}
                    />
                )}
            </div>
            <div className='add-new-volume-tab' >
                <div className='add-icon'onClick={hanbleAddVolumeTab}>
                    <FontAwesomeIcon icon={faPlus}  size="2xl" />
                </div>
            </div>
        </div>

  	);
}

export default VolumeTabs;
