import { useState } from 'react';

function CornerFreqTable({ setCornerFreqs }) {
    const [ table, setTable ] = useState([
        {
            name: '-10 dB',
            freq: '',
            SPL: ''
        },
        {
            name: '-3 dB',
            freq: '',
            SPL: ''
        },
        {
            name: '0 dB',
            freq: '',
            SPL: ''
        }
    ]);

    const handleInputChange = (name, type) => (e) => {
        let newTable = [];

        switch (type) {
            case 'frequency':
                newTable = table.map(item => item.name === name ? { ...item, freq: e.target.value } : item);
                break;
            case 'SPL':
                newTable = table.map(item => 
                    item.name === '0 dB' ? 
                    { ...item, SPL: e.target.value } 
                    : item.name === '-3 dB' ?
                        { ...item, SPL: e.target.value - 3 } 
                        : { ...item, SPL: e.target.value - 10 }  
                );
                break;
            default:
                break;
        }

        setTable(newTable);

        const isValid = newTable.every(item => {
            const isNumberFreqProp = /^\d+$/.test(item.freq);
            const isNumberSPLProp = /^\d+$/.test(item.SPL);
            
            return isNumberFreqProp && isNumberSPLProp;
        });
        
        const data = isValid ? [...newTable] : [];

        setCornerFreqs({
            isValid,
            data
        })
    }
    
    const tableHeader = ['-10 dB', '-3 dB', '0 dB'];

  	return (
        <div className='table-corner-frequencies-container'>
            <span>Set Corner Frequencies for Draft version of Device:</span>
            <div className="table-corner-frequencies">
                <div className="table-bass-header-item"></div>
                { tableHeader.map((item) => 
                    <div key={item} className="table-bass-header-item" >
                        {item}
                    </div>	
                )}
    
                <div>Frequency, Hz</div>

                { table.map((item, index) => 
                    <div className='add-txt-item' key={`${item.name} frequency`}>
                        <label className='icons-label upload-txt-lable'>
                            <input 
                                required 
                                id={`${item.name}-frequency`} 
                                className='' 
                                type="text" 
                                value={item.freq} 
                                onChange={handleInputChange(item.name, 'frequency')} 
                            />
                        </label>
                    </div>
                )}

                <div>SPL, dB</div>

                { table.map((item, index) => 
                    <div className='add-txt-item' key={`${item.name} SPL`}>
                        <label className='icons-label upload-txt-lable'>
                            <input 
                                required 
                                id={`${item.name}-spl`} 
                                className='' 
                                type="text" 
                                value={item.SPL} 
                                disabled={index !== table.length - 1}
                                onChange={handleInputChange(item.name, 'SPL')} 
                            />
                        </label>
                    </div>
                )}
            </div>
        </div>
  	);
}

export default CornerFreqTable;
