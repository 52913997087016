import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { deleteGraphDevice, selectBassGraphData } from "../../reducers/compare.js";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEraser } from '@fortawesome/free-solid-svg-icons';

function GraphLinesNames() {
    const [ blur, setBlur ] = useState('0px');
    const [ blurId, setBlurId ] = useState(null);
    
    const dispatch = useDispatch();

    const selectedDevices = useSelector(selectBassGraphData);
    
    const handleDeleteDevice = (id) => (e) => { dispatch(deleteGraphDevice({ id })) };

    const handleMouseMove = (id, blurValue) => (e) => { e.preventDefault(); setBlur(blurValue); setBlurId(id) }

    return (
        <div className="graph-lines-names">
            {
                selectedDevices.map(device => {
                    const stroke = <svg>
                        <path strokeWidth="4" fill="none" stroke={device.lineColor} d="M0,16h10.666666666666666
                        A5.333333333333333,5.333333333333333,0,1,1,21.333333333333332,16
                        H32M21.333333333333332,16
                        A5.333333333333333,5.333333333333333,0,1,1,10.666666666666666,16"></path>
                    </svg>
                    const name = <span style={{ color: device.lineColor }}>{device.name}</span>
                    const isPointed = blurId === device.id;

                    return (
                        <div 
                            key={device.id}
                            className="graph-lines-names-inner-container"
                            onMouseEnter={handleMouseMove(device.id, '1px')} 
                            onMouseLeave={handleMouseMove(null, '0px')} 
                        >
                            <div 
                                className="devices-graph-lines-names-item" 
                                style={ isPointed ? { filter: `blur(${blur})` } : {}}
                            >
                                {stroke}{name} 
                            </div>
                            <div 
                                className="delete-graph-lines-names-icon"
                                onClick={handleDeleteDevice(device.id)}   
                            > 
                                { isPointed && 
                                    <FontAwesomeIcon icon={faEraser} size="2x" />
                                }
                            </div>
                        </div>
                    );
                })
            }
        </div>
    );
}

export default GraphLinesNames;
