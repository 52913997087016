export const csvToJson = (csv) => {
    let rows = csv.split('\n');

    if (rows[0].includes('\r'))
        rows = rows.map(row => row.slice(0, -1))
    // define field delimiter, four options are available: comma, semicolon, space and tab
    const fieldDelimiter = rows[0].includes(',') ? ',' : rows[0].includes(';') ? ';' : rows[0].includes('\t') ? '\t' : ' ';
    
    // clio data are only numbers, there are no strings in it
    // so string delimiter exist only when we use field delimiter == ',' and have ',' as float number delimiter
    // define string delimiter, but if there are no float number in first row there will be an error
    const stringDelimiter = rows[1].includes('\'') ? '\'' : rows[1].includes('"') ? '"' : 'no string in row';

    if (stringDelimiter !== 'no string in row') {
        // regexp to find and replace comma (,) symbol in float numbers with * symbol
        const regexp = new RegExp(`${stringDelimiter}(.*?),(.*?)${stringDelimiter}`, 'g');
        // update rows array with replaced comma symbol in float numbers 
        rows = rows.map(row => row.replace(regexp, (match, p1, p2) => `${p1}.${p2}`));
    }

    const header = rows[0].split(fieldDelimiter);

    const json = [];

    for (let i = 1; i < rows.length - 1; i++) {
        const values = rows[i].split(fieldDelimiter);

        const obj = {};

        for (let j = 0; j < header.length; j++) {
            obj[header[j]] = parseFloat(values[j].replace(',', '.'));
        }

        json.push(obj);
    }

    return json;
};

export const txtToJson = (txt) => {
    const rows = txt.split('\r\n').map(s => s.replace(/\s+/g, ','));

    const header = rows[0].split(',');

    const json = [];

    for (let i = 1; i < rows.length - 1; i++) {
        const values = rows[i].split(',');

        const obj = {};

        for (let j = 0; j < header.length; j++) {
            obj[header[j]] = parseFloat(values[j].replace(',', '.'));
        }

        json.push(obj);
    }

    return json;
}

export const getColor = (name) => {
    let hashCode = 0;

    for (let i = 0; i < name.length; i++) {
        hashCode = name.charCodeAt(i) + ((hashCode << 5) - hashCode);
    }

    let color = "#";
    for (let i = 0; i < 3; i++) {
        const value = (hashCode >> (i * 8)) & 0xff;
        color += ("00" + value.toString(16)).slice(-2);
    }

    return color;
}

export const companyColors = {
    'Amazon': '#4472C4',
    'Apple': '#66FFFF',
    'Bose': '#757171',
    'Facebook': '#70AD47',
    'Google': '#FBA3A3',
    'Harman Kardon': '#9900CC',
    'JBL': '#FF9900',
    'SberBoom': '#FF00FF',
    'Sonos': '#92D050',
    'Vifa': '#FFCC66',
    'VK': '#00CCFF',
    'Xiaomi': '#F8CBAD',
    'Yandex': '#82ca9d'
};

export const chooseCategory = (size) => {
    const category = {
        'Mini': {
            name: 'xs',
            min: 0,
            max: 0.625
        },
        'Small': {
            name: 's',
            min: 0.625,
            max: 1.30
        },
        'Medium': {
            name: 'm',
            min: 1.30,
            max: 2.5
        },
        'Large': {
            name: 'l',
            min: 2.5,
            max: 5
        },
        'Extra Large': {
            name: 'xl',
            min: 5,
            max: 99999
        },
    };

    let retval = 'xs';

    Object.keys(category).map(cat => {
        const { name, min, max } = category[cat]
        if (size >= min && size <= max) 
            retval = name;
    });

    return retval;
}

export const createUID = () =>  Date.now().toString(12);

export const getTicksForGraph = (type, data, startValue = 0, endValue = 100) => {
    const minmaxValuesArr = data.map((d, i) => {
        const arr = d.items.map(item => +item[type])

        return {
            max: Math.max(...arr),
            min: Math.min(...arr)
        }
    })

    const maxValue = Math.max(...minmaxValuesArr.map(val => val.max));
    const minValue = Math.min(...minmaxValuesArr.map(val => val.min));
    const maxGraphValue = data.length ? Math.ceil((maxValue + 10) / 10) * 10 : endValue;
    let minGraphValue = data.length ? Math.ceil((minValue - 10) / 10) * 10 : startValue;
    
    let YAxisTicks = [];

    if (minGraphValue < 10) minGraphValue = minGraphValue + 10;
    
    for (let i = minGraphValue - 10; i < maxGraphValue + 10; i = i + 10) {
        YAxisTicks.push(i);
    }

    return {
        ticks: [...YAxisTicks],
        maxValue: maxGraphValue,
        minValue: minGraphValue
    }
}

export const toLowerCaseAndReplaceSymbolsWithDash = str => 
    str.match(/([a-zA-Z0-9]+)/g)
    .join('-')
    .toLowerCase()

export const findDeviceById = (devices, id) => {
    const device = devices.find(device => device.id === id);

    if (!device) return null;

    return { 
        company: toLowerCaseAndReplaceSymbolsWithDash(device.company),
        name: toLowerCaseAndReplaceSymbolsWithDash(device.name),
        volume: `volume-${device.volume}`
    }
};

export const findDeviceByCompanyAndCategory = (devices, categories, filter) => {
    const { company, category } = filter;

    const filteredDevices = devices.filter(device => {
        if (category === 'All')
            return device.company.toLowerCase() === company.toLowerCase()

        return device.company === company && categories[device.category] === category
    });

    if (filteredDevices.length === 0)
        return null

    return { 
        company: toLowerCaseAndReplaceSymbolsWithDash(filteredDevices[0].company),
        name: toLowerCaseAndReplaceSymbolsWithDash(filteredDevices[0].name),
        volume: `volume-${filteredDevices[0].volume}`
    };
}

export const createFilterForDevice = (devices, companyName, speakerName, speakerVolume ) => {
    const company = companyName.replace(/[^a-zA-Z0-9]/g, ' ');
    const volume = +speakerVolume.replace('volume-','');
    
    const device = devices.find(device => (
        toLowerCaseAndReplaceSymbolsWithDash(device.name) === speakerName && 
        device.volume === volume
    ) );

    if (!device) return null;
    if (device.company.toLowerCase() !== company) return null;

    return { company, deviceId: device.id }
    
}

export const makeCsvFromMeasurements = (measurements) => {
    let csv = 'Freq[Hz],CHB[dBSPL],Phase[Deg],THD[%],R&B[%]\n';

    measurements.forEach(measurement => {
        const { 'Freq[Hz]': freq, 'CHB[dBSPL]': chb, 'Phase[Deg]': phase, 'THD[%]': thd, 'R&B[%]': rb } = measurement;

        csv += `${freq},${chb},${phase},${thd},${rb}\n`;
    });

    return csv;
}

export const groupDevicesBySameName = (devices) => {
    const newDevices = [];

    devices.map(device => {
        if (newDevices.length === 0) {
            newDevices.push ({
                name: device.name,
                category: device.category,
                size: device.size,
                company: device.company,
                ids: [{ 
                    id: device.id, 
                    volume: device.volume,
                    lineColor: device.lineColor 
                }],
                menuStatus: 'closed'
            })
        } else {
            let isExist = 0;

            for (let i = 0; i < newDevices.length; i++) {
                if (newDevices[i].name === device.name) {
                    newDevices[i].ids.push({ 
                        id: device.id, 
                        volume: device.volume, 
                        lineColor: device.lineColor 
                    });
                    isExist = 1; 
                    break;
                }     
            }
            
            if (isExist === 0) {
                newDevices.push ({
                    name: device.name,
                    category: device.category,
                    size: device.size,
                    company: device.company,
                    lineColor: device.lineColor,
                    ids: [{ 
                        id: device.id, 
                        volume: device.volume,
                        lineColor: device.lineColor 
                    }],
                    menuStatus: 'closed'
                })
            } 
        }
    })

    return newDevices;
}
