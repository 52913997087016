
function DraftCheckBox({ isDraft, setIsDraft }) {
    
  	return (
        <div className='checkbox-container'>
            <label htmlFor="draft-checkbox" > 
                Is Draft Device?
                <input 
                    type="checkbox" 
                    id="draft-checkbox"
                    checked={isDraft}
                    onChange={() => {setIsDraft(!isDraft);}}
                />
                <span className="checkmark"></span>    
            </label>
        </div>
  	);
}

export default DraftCheckBox;
