import {  useSelector } from 'react-redux';

import { selectSizeCategoriesObject } from '../../reducers/devices.js';

function DeviceListItemsHeader({ showItems, setshowItems, name, company, size, category }) {
    const categories = useSelector(selectSizeCategoriesObject);

    const itemStyle = {
        borderBottom: showItems ? '2px solid black' : 'none',
    }

    const nameStyle = {
        color: showItems ? '#6495ed' : 'black',
    }

    return (
        <div className="devices-list-item" 
            onClick={(e) => { setshowItems(!showItems) }}
            style={itemStyle}
        >
            <div className="devices-list-item-main">
                <div className="devices-list-item-main-name" style={nameStyle}>
                    {name}
                </div>
                <div className="devices-list-item-main-company">
                    {company}
                </div>
                <div className="devices-list-item-main-volume">
                    {size} liters
                </div>
                <div className="devices-list-item-main-category">
                    {categories[category]}
                </div>
            </div>
        </div>
    )
}

export default DeviceListItemsHeader;
