import { forwardRef } from "react";

const PanelFormVolumeInput = forwardRef(({ text, id, placeholder, defaultValue }, ref) => {
    return (
        <div className="volume-tab-input">
            <label htmlFor="feedback-name">{text}:</label><br/>
            <input 
                required 
                ref={ref}
                type="text" 
                id={`${id}-name`}
                placeholder={placeholder}
                defaultValue={defaultValue}
            />
        </div>
    )
})


export default PanelFormVolumeInput;
