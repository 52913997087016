function Loader() {

    // return <div className="loading" />
    // return (
    //     <div className="loading">
    //         <div id="computer" />
    //     </div>
    // )
    return (
        <div className="loading-container">
            <div className="circle purple-1" />
            <div className="circle purple-2" />
            <div className="circle purple-3" />
            <div className="circle purple-4" />
            <div className="circle purple-5" />
            <div className="circle purple-6" />
            <div className="circle purple-7" />
            <div className="circle purple-8" />
            <div className="circle purple-9" />
        </div>
    )

}

export default Loader;
